import React from 'react';
import Loadable from 'react-loadable';

const Loading = () => {
  return <div />;
};

const Home = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Home/Home`),
  loading: Loading,
});

const SupportHome = Loadable({
  loader: () => import(`../views/Support/SupportHome`),
  loading: Loading,
});

const PaymentsHome = Loadable({
  loader: () => import(`../views/Finance/Payments/PaymentsHome`),
  loading: Loading,
});

const MessagesReceived = Loadable({
  loader: () => import(`../views/DefaultWorkflow/MessagesReceived/MessagesReceived`),
  loading: Loading,
});

const MessagesSent = Loadable({
  loader: () => import(`../views/DefaultWorkflow/MessagesSent/MessagesSent`),
  loading: Loading,
});

const People = Loadable({
  loader: () => import(`../views/DefaultWorkflow/People/People`),
  loading: Loading,
});

const Badges = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Badges/Badges`),
  loading: Loading,
});

const Keys = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Keys/Keys`),
  loading: Loading,
});

const BadgeApplications = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeApplications/BadgeApplications`),
  loading: Loading,
});

const Audits = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Audits/Audits`),
  loading: Loading,
});

const AuditDetails = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Audits/AuditDetails`),
  loading: Loading,
});

const AuthorizedSignatoryAuditing = Loadable({
  loader: () => import(`../views/DefaultWorkflow/AuthorizedSignatoryAuditing/AuthorizedSignatoryAuditing`),
  loading: Loading,
});

const AuthorizedSignatories = Loadable({
  loader: () => import(`../views/DefaultWorkflow/AuthorizedSignatories/AuthorizedSignatories`),
  loading: Loading,
});

const Companies = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Companies/Companies`),
  loading: Loading,
});

const Managers = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Managers/Managers`),
  loading: Loading,
});

const TrustedAgents = Loadable({
  loader: () => import(`../views/DefaultWorkflow/TrustedAgents/TrustedAgents`),
  loading: Loading,
});

const ActionItems = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ActionItems/ActionItems`),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Settings/Settings`),
  loading: Loading,
});

const QuickAudits = Loadable({
  loader: () => import(`../views/DefaultWorkflow/QuickAudits/QuickAudits`),
  loading: Loading,
});

const BadgeOffice = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/BadgeOffice`),
  loading: Loading,
});

const BadgeAuditCertifications = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeAuditCertifications/BadgeAuditCertifications`),
  loading: Loading,
});

const Staff = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Staff/Staff`),
  loading: Loading,
});

const Appointments = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Appointments/Appointments`),
  loading: Loading,
});

// const ExternalDocument = Loadable({
//   loader: () => import(`../views/DefaultWorkflow/ExternalDocumentFrame/ExternalDocumentFrame`),
//   loading: Loading,
// });

const DocSpringDocument = Loadable({
  loader: () => import(`../views/DefaultWorkflow/DocSpringDocument/DocSpringDocument`),
  loading: Loading,
});

const ResponseDocuments = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ResponseDocuments/ResponseDocuments`),
  loading: Loading,
});

const Reminders = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Reminders/Reminders`),
  loading: Loading,
});

const ReceiveResponses = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ReceiveResponses/ReceiveResponses`),
  loading: Loading,
});

const Reports = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Reports/Reports`),
  loading: Loading,
});

const Files = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Files/Files`),
  loading: Loading,
});

const BadgeOfficeFingerprintsTools = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/Fingerprints/BadgeOfficeFingerprintsTools`),
  loading: Loading,
});

const PacsReconciliations = Loadable({
  loader: () => import(`../views/DefaultWorkflow/PacsReconciliations/PacsReconciliations`),
  loading: Loading,
});

const PacsReconcileSingle = Loadable({
  loader: () => import(`../views/DefaultWorkflow/PacsReconciliations/PacsReconcileSingle`),
  loading: Loading,
});

const AdminBadgeApplications = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/BadgeApplications/AdminBadgeApplications`),
  loading: Loading,
});

const AdminBadgeApplicationConfigs = Loadable({
  loader: () =>
    import(`../views/DefaultWorkflow/ApplicationAdministration/BadgeApplications/AdminBadgeApplicationConfigs`),
  loading: Loading,
});

const AdminBadgeApplicationInvestigations = Loadable({
  loader: () =>
    import(`../views/DefaultWorkflow/ApplicationAdministration/BadgeApplications/AdminBadgeApplicationInvestigations`),
  loading: Loading,
});

const AdminBadges = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Badges/AdminBadges`),
  loading: Loading,
});

const AdminBadgeInvestigations = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Badges/AdminBadgeInvestigations`),
  loading: Loading,
});

const AdminBadgeTypes = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Badges/BadgeTypes/AdminBadgeTypes`),
  loading: Loading,
});

const AdminBadgeTypeEditor = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Badges/BadgeTypes/AdminBadgeTypeEditor`),
  loading: Loading,
});

const AdminConfig = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Config/AdminConfig`),
  loading: Loading,
});

const AdminFiles = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Files/AdminFiles`),
  loading: Loading,
});

const AdminFingerprints = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Fingerprints/AdminFingerprints`),
  loading: Loading,
});

const AdminDocuments = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Documents/AdminDocuments`),
  loading: Loading,
});

const AdminImports = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Imports/AdminImports`),
  loading: Loading,
});

const AdminJobs = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Jobs/AdminJobs`),
  loading: Loading,
});

const AdminPayments = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Payments/AdminPayments`),
  loading: Loading,
});

const TscMapping = Loadable({
  loader: () => import(`../views/DefaultWorkflow/TscMapping/TscMapping`),
  loading: Loading,
});

const TscMappingSingle = Loadable({
  loader: () => import(`../views/DefaultWorkflow/TscMapping/TscMappingSingle`),
  loading: Loading,
});

const Prototype = Loadable({
  loader: () => import(`../views/Prototype/Prototype`),
  loading: Loading,
});

const ContactInformationSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/ContactInformationSettings`),
  loading: Loading,
});

const MissingInPacs = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/Compliance/MissingInPacs`),
  loading: Loading,
});

const ComplianceReview = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/Compliance/ComplianceReview`),
  loading: Loading,
});

const DistributableMedia = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/DistributableMedia/DistributableMedia`),
  loading: Loading,
});

const Printing = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/Printing/Printing`),
  loading: Loading,
});

const Payments = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/Payments`),
  loading: Loading,
});

const PaymentRateClasses = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Payments/PaymentRateClasses/PaymentRateClasses`),
  loading: Loading,
});

const PaymentsSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Payments/PaymentsSettings`),
  loading: Loading,
});

const BadgeApplicationPaymentsListing = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Payments/BadgeApplicationPayments/BadgeApplicationPaymentsListing`),
  loading: Loading,
});

const ViolationsSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/ViolationsSettings/ViolationsSettings`),
  loading: Loading,
});

const ViolationsListing = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Violations/ViolationsListing`),
  loading: Loading,
});

const AppointmentSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/AppointmentSettings`),
  loading: Loading,
});

const AutomationSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/AutomationSettings/AutomationSettings`),
  loading: Loading,
});

const BadgeApplicationSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/BadgeApplicationSettings`),
  loading: Loading,
});

const BadgeExpirationSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/AutomationSettings/BadgeExpirationSettings`),
  loading: Loading,
});

const StopListSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/AutomationSettings/StopListSettings`),
  loading: Loading,
});

const NotificationsSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/NotificationsSettings`),
  loading: Loading,
});

const ActionItemSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/ActionItemSettings`),
  loading: Loading,
});

const FormsAssignments = Loadable({
  loader: () => import(`../views/DefaultWorkflow/FormsAssignments/FormsAssignments`),
  loading: Loading,
});

const MyAppointments = Loadable({
  loader: () => import(`../views/DefaultWorkflow/MyAppointments/MyAppointments`),
  loading: Loading,
});

const MyTraining = Loadable({
  loader: () => import(`../views/DefaultWorkflow/MyTraining/MyTraining`),
  loading: Loading,
});

const AdminPages = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Admin`),
  loading: Loading,
});

const TscCrossCheck = Loadable({
  loader: () => import(`../views/DefaultWorkflow/TscCrossCheck/TscCrossCheck`),
  loading: Loading,
});

const DacSynchronization = Loadable({
  loader: () => import(`../views/DefaultWorkflow/DacSynchronization/DacSynchronization`),
  loading: Loading,
});

const TrainingResultsSynchronization = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Training/TrainingResultsSync`),
  loading: Loading,
});

const TrainingCourses = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/Training/TrainingCourses`),
  loading: Loading,
});

const TrainingAssignment = Loadable({
  loader: () => import(`../views/Training/TrainingAssignment`),
  loading: Loading,
});

const Renewals = Loadable({
  loader: () => import('../views/DefaultWorkflow/Renewals/Renewals'),
  loading: Loading,
});

const AuditingResponseDocument = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Audits/AuditingResponseDocument/AuditingResponseDocument`),
  loading: Loading,
});

const AdminImpersonateUser = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/AdminImpersonateUser/AdminImpersonateUser`),
  loading: Loading,
});

const AdminIntegrations = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Integrations/Integrations`),
  loading: Loading,
});

const AdminIntegrationsTsc = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Integrations/TscConfigs`),
  loading: Loading,
});

const AdminIntegrationsSupport = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Integrations/SupportConfig`),
  loading: Loading,
});

const AdminIntegrationsPrintScanFingerprints = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Integrations/PrintScanFingerprintsConfig`),
  loading: Loading,
});

const AdminIntegrationsLakotaFingerprints = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Integrations/LakotaFingerprintsConfig`),
  loading: Loading,
});

const AdminIntegrationsPacs = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Integrations/PacsConfigs`),
  loading: Loading,
});

const AdminIntegrationsIet = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Integrations/IetConfigs`),
  loading: Loading,
});

const AdminIntegrationsDigicast = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Integrations/DigicastConfig`),
  loading: Loading,
});

const AdminIntegrationsSsi = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Integrations/SsiConfigs`),
  loading: Loading,
});

const AdminIntegrationsStripe = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Integrations/payments/Stripe/StripeConfig`),
  loading: Loading,
});

const AdminAppointments = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/Appointments/AdminAppointments`),
  loading: Loading,
});

const MergeUsers = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/MergeUsers/MergeUsers`),
  loading: Loading,
});

const EmailSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/EmailSettingsPage`),
  loading: Loading,
});

const CompanyTypes = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/CompanyTypes/CompanyTypes`),
  loading: Loading,
});

const DocumentTesting = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/DocumentTesting/DocumentTesting`),
  loading: Loading,
});

const Forms = Loadable({
  loader: () => import(`../views/DefaultWorkflow/Forms/Forms`),
  loading: Loading,
});

const AdminCommunications = Loadable({
  loader: () => import(`../views/DefaultWorkflow/ApplicationAdministration/AdminCommunications/AdminCommunications`),
  loading: Loading,
});

const KioskSettings = Loadable({
  loader: () => import(`../views/DefaultWorkflow/BadgeOffice/pages/KioskSettings/KioskSettings`),
  loading: Loading,
});

export default [
  {
    path: `/support`,
    exact: true,
    name: 'SupportHome',
    component: SupportHome,
  },
  {
    path: `/default/prototype-testing`,
    exact: true,
    name: 'Prototype',
    component: Prototype,
  },
  {
    path: `/default/home`,
    exact: true,
    name: 'Home',
    component: Home,
  },
  {
    path: `/default/finance/payments`,
    exact: true,
    name: 'PaymentsHome',
    component: PaymentsHome,
  },
  {
    path: `/default/messages-received`,
    exact: true,
    name: 'Messages Received',
    component: MessagesReceived,
  },
  {
    path: `/default/messages-sent`,
    exact: true,
    name: 'Messages Sent',
    component: MessagesSent,
  },
  {
    path: `/default/people`,
    exact: true,
    name: 'People',
    component: People,
  },
  {
    path: `/default/badges`,
    exact: true,
    name: 'Badges',
    component: Badges,
  },
  {
    path: `/default/keys`,
    exact: true,
    name: 'Keys',
    component: Keys,
  },
  {
    path: `/default/badge-applications`,
    exact: true,
    name: 'Badge Applications',
    component: BadgeApplications,
  },

  {
    path: `/default/audits/audit-details/:auditUuid?`,
    exact: true,
    name: 'Audit Details',
    component: AuditDetails,
  },

  {
    path: `/default/badge-office/audits/:identifier?`,
    exact: true,
    name: 'Audits',
    component: Audits,
  },
  {
    path: `/default/authorized-signatory-auditing`,
    exact: true,
    name: 'Audits',
    component: AuthorizedSignatoryAuditing,
  },
  {
    path: `/default/authorized-signatory-auditing/sign-auditing-response-document/:auditingResponseDocumentUuid`,
    exact: true,
    name: 'Audits',
    component: AuditingResponseDocument,
  },
  {
    path: `/default/authorized-signatories`,
    exact: true,
    name: 'Authorized Signatories',
    component: AuthorizedSignatories,
  },
  {
    path: `/default/companies`,
    exact: true,
    name: 'Companies',
    component: Companies,
  },
  {
    path: `/default/managers/:companyName/:companyuuid`,
    exact: true,
    name: 'Managers',
    component: Managers,
  },
  {
    path: `/default/badge-office/trusted-agents`,
    exact: true,
    name: 'Trusted Agents',
    component: TrustedAgents,
  },
  {
    path: `/default/action-items`,
    exact: true,
    name: 'Action Item',
    component: ActionItems,
  },
  {
    path: `/default/certifications`,
    exact: true,
    name: 'Certifications',
    component: BadgeAuditCertifications,
  },
  {
    path: `/default/settings`,
    exact: true,
    name: 'Settings',
    component: Settings,
  },
  {
    path: `/default/quick-audits/:uuid`,
    exact: true,
    name: 'Quick Audits',
    component: QuickAudits,
  },
  {
    path: `/default/badge-office`,
    exact: true,
    name: 'Badge Office',
    component: BadgeOffice,
  },
  {
    path: `/default/external-document/:transactionUuid`,
    exact: true,
    name: 'External Document',
    component: DocSpringDocument,
  },

  {
    path: `/default/staff`,
    exact: true,
    name: 'Staff',
    component: Staff,
  },

  {
    path: `/default/appointments`,
    exact: true,
    name: 'Appointments',
    component: Appointments,
  },

  {
    path: `/default/audits/:audit_uuid/response-documents`,
    name: 'Response Documents',
    component: ResponseDocuments,
  },

  {
    path: `/default/audits/:audit_uuid/reminders`,
    name: 'Reminders',
    component: Reminders,
  },

  {
    path: `/default/audits/:audit_uuid/receive-responses`,
    name: 'Receive Responses',
    component: ReceiveResponses,
  },
  {
    path: `/app-admin/config`,
    name: 'Airport Config',
    component: AdminConfig,
  },
  {
    path: `/app-admin/impersonate-user`,
    name: 'Impersonate User',
    component: AdminImpersonateUser,
  },
  {
    path: `/app-admin/jobs`,
    name: 'Jobs',
    component: AdminJobs,
  },
  {
    path: `/app-admin/payments`,
    name: 'AdminPayments',
    component: AdminPayments,
  },
  {
    path: `/app-admin/badge-applications/configs`,
    name: 'Badge Application Configs',
    component: AdminBadgeApplicationConfigs,
  },
  {
    path: `/app-admin/badge-applications/investigations`,
    name: 'Badge Application Investigations',
    component: AdminBadgeApplicationInvestigations,
  },
  {
    path: `/app-admin/badge-applications`,
    name: 'Badge Applications',
    component: AdminBadgeApplications,
  },
  {
    path: `/app-admin/badges/badge-types/:badgeTypeUuid`,
    name: 'AdminBadgeTypeEditor',
    component: AdminBadgeTypeEditor,
  },
  {
    path: `/app-admin/badges/badge-types`,
    name: 'AdminBadgeTypes',
    component: AdminBadgeTypes,
  },
  {
    path: `/app-admin/badges/badge-investigations`,
    name: 'AdminBadgeInvestigations',
    component: AdminBadgeInvestigations,
  },
  {
    path: `/app-admin/badges`,
    name: 'AdminBadges',
    component: AdminBadges,
  },
  {
    path: `/app-admin/files`,
    name: 'Files',
    component: AdminFiles,
  },
  {
    path: `/app-admin/fingerprints`,
    name: 'Fingerprints',
    component: AdminFingerprints,
  },
  {
    path: `/app-admin/documents`,
    name: 'Documents',
    component: AdminDocuments,
  },
  {
    path: `/app-admin/imports`,
    name: 'Imports',
    component: AdminImports,
  },
  {
    path: `/default/badge-office/tsc-cross-check`,
    exact: true,
    name: 'TscCrossCheck',
    component: TscCrossCheck,
  },
  {
    path: `/default/reports`,
    exact: true,
    name: 'Reports',
    component: Reports,
  },
  {
    path: `/default/badge-office/files`,
    exact: true,
    name: 'Files',
    component: Files,
  },
  {
    path: `/default/badge-office/fingerprints`,
    exact: true,
    name: 'Fingerprints',
    component: BadgeOfficeFingerprintsTools,
  },
  {
    path: `/default/pacs-reconciliations`,
    exact: true,
    name: 'PacsReconciliations',
    component: PacsReconciliations,
  },
  {
    path: `/default/pacs-reconcile-single/:badge_id`,
    exact: true,
    name: 'PacsReconcileSingle',
    component: PacsReconcileSingle,
  },
  {
    path: `/default/tsc-mapping`,
    exact: true,
    name: 'TscMapping',
    component: TscMapping,
  },
  {
    path: `/default/tsc-reconcile-single/:tsc_id`,
    exact: true,
    name: 'TscMappingSingle',
    component: TscMappingSingle,
  },
  {
    path: `/default/badge-office/contact-information`,
    exact: true,
    name: 'ContactInformationSettings',
    component: ContactInformationSettings,
  },
  {
    path: `/default/badge-office/compliance-review/pacs-missing`,
    exact: true,
    name: 'MissingInPacs',
    component: MissingInPacs,
  },
  {
    path: `/default/badge-office/compliance-review`,
    exact: true,
    name: 'ComplianceReview',
    component: ComplianceReview,
  },
  {
    path: `/default/badge-office/distributable-media`,
    exact: true,
    name: 'DistributableMedia',
    component: DistributableMedia,
  },
  {
    path: `/default/badge-office/printing`,
    exact: true,
    name: 'Printing',
    component: Printing,
  },
  {
    path: `/default/badge-office/payments/badge-applications`,
    exact: true,
    name: 'BadgeApplicationPaymentsListing',
    component: BadgeApplicationPaymentsListing,
  },
  {
    path: `/default/badge-office/payments/settings`,
    exact: true,
    name: 'PaymentsSettings',
    component: PaymentsSettings,
  },
  {
    path: `/default/badge-office/payments`,
    exact: true,
    name: 'Payments',
    component: Payments,
  },
  {
    path: `/default/badge-office/payments/rate-classes`,
    exact: true,
    name: 'PaymentRateClasses',
    component: PaymentRateClasses,
  },
  {
    path: `/default/badge-office/violations`,
    exact: true,
    name: 'ViolationsSettings',
    component: ViolationsSettings,
  },
  {
    path: `/default/violations`,
    exact: true,
    name: 'ViolationsListing',
    component: ViolationsListing,
  },
  {
    path: `/default/badge-office/appointment-settings`,
    exact: true,
    name: 'AppointmentSettings',
    component: AppointmentSettings,
  },
  {
    path: `/app-admin/company-types`,
    exact: true,
    name: 'CompanyTypes',
    component: CompanyTypes,
  },
  {
    path: `/default/badge-office/automations-settings`,
    exact: true,
    name: 'AutomationSettings',
    component: AutomationSettings,
  },
  {
    path: `/default/badge-office/badge-application-settings`,
    exact: true,
    name: 'BadgeApplicationSettings',
    component: BadgeApplicationSettings,
  },
  {
    path: `/default/badge-office/automations-settings/badge-expiration`,
    exact: true,
    name: 'BadgeExpirationSettings',
    component: BadgeExpirationSettings,
  },
  {
    path: `/default/badge-office/automations-settings/stop-list`,
    exact: true,
    name: 'StopListSettings',
    component: StopListSettings,
  },
  {
    path: `/default/badge-office/notifications-settings`,
    exact: true,
    name: 'NotificationsSettings',
    component: NotificationsSettings,
  },
  {
    path: `/default/badge-office/action-item-settings`,
    exact: true,
    name: 'ActionItemSettings',
    component: ActionItemSettings,
  },
  {
    path: `/app-admin/tsc-synchronization`,
    name: 'DacSynchronization',
    component: DacSynchronization,
  },
  {
    path: `/app-admin/training-results-synchronization`,
    name: 'TrainingResultsSynchronization',
    component: TrainingResultsSynchronization,
  },
  {
    path: `/default/badge-office/training-courses`,
    exact: true,
    name: 'TrainingCourses',
    component: TrainingCourses,
  },
  {
    path: `/training/assignment/:assignmentUuid`,
    exact: true,
    name: 'TrainingAssignment',
    component: TrainingAssignment,
  },
  {
    path: `/default/app-admin`,
    exact: true,
    name: 'AdminPages',
    component: AdminPages,
  },
  {
    path: `/default/renewals`,
    exact: true,
    name: 'Renewals',
    component: Renewals,
  },
  {
    path: `/app-admin/integrations`,
    exact: true,
    name: 'AdminIntegrations',
    component: AdminIntegrations,
  },
  {
    path: `/app-admin/integrations/tsc`,
    name: 'AdminIntegrationsTsc',
    component: AdminIntegrationsTsc,
  },
  {
    path: `/app-admin/integrations/support`,
    name: 'AdminIntegrationsSupport',
    component: AdminIntegrationsSupport,
  },
  {
    path: `/app-admin/integrations/printscan`,
    name: 'AdminIntegrationsFingerprinting',
    component: AdminIntegrationsPrintScanFingerprints,
  },
  {
    path: `/app-admin/integrations/lakota`,
    name: 'AdminIntegrationsFingerprinting',
    component: AdminIntegrationsLakotaFingerprints,
  },
  {
    path: `/app-admin/integrations/iet`,
    name: 'AdminIntegrationsIet',
    component: AdminIntegrationsIet,
  },
  {
    path: `/app-admin/integrations/digicast`,
    name: 'AdminIntegrationsDigicast',
    component: AdminIntegrationsDigicast,
  },
  {
    path: `/app-admin/integrations/pacs`,
    name: 'AdminIntegrationsPacs',
    component: AdminIntegrationsPacs,
  },
  {
    path: `/app-admin/integrations/ssi`,
    name: 'AdminIntegrationsSsi',
    component: AdminIntegrationsSsi,
  },
  {
    path: `/app-admin/integrations/stripe`,
    name: 'AdminIntegrationsStripe',
    component: AdminIntegrationsStripe,
  },
  {
    path: `/app-admin/appointments`,
    name: 'AdminAppointments',
    component: AdminAppointments,
  },
  {
    path: `/app-admin/merge-users`,
    name: 'MergeUsers',
    component: MergeUsers,
  },
  {
    path: `/default/badge-office/email`,
    name: 'EmailSettings',
    component: EmailSettings,
  },
  {
    path: `/app-admin/document-testing`,
    name: 'DocumentTesting',
    component: DocumentTesting,
  },
  {
    path: `/default/badge-office/forms-assignments`,
    name: 'FormsAssignments',
    component: FormsAssignments,
  },
  {
    path: `/default/my-appointments`,
    name: 'MyAppointments',
    component: MyAppointments,
  },
  {
    path: `/default/my-training`,
    name: 'MyTraining',
    component: MyTraining,
  },
  {
    path: `/default/forms`,
    exact: true,
    name: 'Forms',
    component: Forms,
  },
  {
    path: `/app-admin/communications`,
    name: 'AdminCommunications',
    component: AdminCommunications,
  },
  {
    path: `/default/badge-office/kiosk-settings`,
    exact: true,
    name: 'KioskSettings',
    component: KioskSettings,
  },
];
