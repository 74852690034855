import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import { Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { styled } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../shared/components/AirBadgeModal/AirBadgeModal';
import { AppContext } from '../../../App';
import InputWithCharacterLimit from '../../../shared/components/InputWithCharacterLimit/InputWithCharacterLimit';
import violationsApi from '../../../ajax/Violations/violationsApi';
import { handleError } from '../../../utils';
import { getViolationStatusLabel } from './ChangeViolationStatusModal';
import StaggeredDatePicker from '../../../shared/components/DatePickers/StaggeredDatePicker';

const RowWithSpacer = styled(Row)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export default function EditViolationModal({ isOpen, onClose, violation }) {
  const app = useContext(AppContext);
  const [violationDate, setViolationDate] = useState(moment());
  const [isBadgeConfiscated, setIsBadgeConfiscated] = useState(null);
  const [isBadgeSuspended, setIsBadgeSuspended] = useState(null);
  const [isRetrainingRequired, setIsRetrainingRequired] = useState(null);
  const [fineAmount, setFineAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [comment, setComment] = useState('');
  const [wasChanged, setWasChanged] = useState(false);
  const [mustBeCompletedBy, setMustBeCompletedBy] = useState(null);
  const [isValidDate, setIsValidDate] = useState(true);

  const reset = () => {
    setViolationDate(moment());
    setIsBadgeConfiscated(null);
    setIsBadgeSuspended(null);
    setIsRetrainingRequired(null);
    setFineAmount(0);
    setDescription('');
    setComment('');
    setWasChanged(false);
    setMustBeCompletedBy(null);
    setIsValidDate(true);
  };

  const onCloseModal = () => {
    reset();
    onClose({ shouldReload: false });
  };

  const isSaveDisabled = () => {
    if (mustBeCompletedBy && !isValidDate) return true;
    return (
      !wasChanged ||
      !violationDate ||
      isBadgeConfiscated === null ||
      isBadgeSuspended === null ||
      isRetrainingRequired === null ||
      !description
    );
  };

  const saveChanges = () => {
    const updates = {
      mustBeCompletedBy,
      date: violationDate,
      fineAmount: Number(fineAmount),
      isRetrainingRequired: isRetrainingRequired === true,
      isBadgeConfiscated: isBadgeConfiscated === true,
      isBadgeSuspended: isBadgeSuspended === true,
      description,
      additionalComments: comment,
    };

    app.api.toggleLoading(true);
    violationsApi
      .updateViolation({ violationUuid: violation.uuid, updates })
      .then(({ success }) => {
        if (success) {
          reset();
          onClose({ shouldReload: true });
        } else {
          app.api.toggleLoading(false);
        }
      })
      .catch(error => {
        handleError({ error });
        app.api.toggleLoading(false);
      });
  };

  const onModalClicked = () => {
    const trigger = document.querySelector('.violation-date-trigger .datetime-picker-popup');
    if (trigger?.style?.display !== 'none') {
      document.querySelector('#collectedDate').click();
    }
  };

  const validateDate = yearMonthDayString => {
    const [year, month, day] = yearMonthDayString.split('-');
    const selectedDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    setIsValidDate(selectedDate >= currentDate);
  };

  useEffect(() => {
    if (!isOpen || !violation) return;

    setMustBeCompletedBy(violation.mustBeCompletedBy);
    setViolationDate(moment(violation.violationDate));
    setIsBadgeConfiscated(!!violation.isBadgeConfiscated);
    setIsBadgeSuspended(!!violation.isBadgeSuspended);
    setIsRetrainingRequired(!!violation.isRetrainingRequired);
    setFineAmount(violation.fineAmount);
    setDescription(violation.description);
  }, [isOpen, violation]);

  if (!isOpen || !violation) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Edit Violation Details"
      isOpen={isOpen}
      onClose={onCloseModal}
      saveLabel="Save Changes"
      saveDisabled={isSaveDisabled()}
      onSave={saveChanges}
      onModalClicked={onModalClicked}
    >
      <RowWithSpacer>
        <Col>
          <FormGroup>
            <Label className="m-0">
              Must Complete By
              <span style={{ fontSize: 'smaller', fontStyle: 'italic', marginLeft: 5 }}>(optional)</span>
            </Label>
            <div style={{ fontSize: 'smaller', fontStyle: 'italic', marginBottom: 8 }}>
              time will be set to midnight
            </div>
            <StaggeredDatePicker
              startingYear={new Date().getFullYear()}
              endingYear={new Date().getFullYear() + 1}
              defaultValue={mustBeCompletedBy}
              onInvalidDate={() => setMustBeCompletedBy(null)}
              onValidDate={({ year, month, day }) => {
                setMustBeCompletedBy(`${year}-${month}-${day}`);
                validateDate(`${year}-${month}-${day}`);
                setWasChanged(true);
              }}
            />
            {wasChanged && mustBeCompletedBy && !isValidDate && (
              <div style={{ marginTop: 12 }} className="text-error">
                Date cannot be in the past
              </div>
            )}
          </FormGroup>
        </Col>
      </RowWithSpacer>
      <RowWithSpacer>
        <Col>
          <FormGroup>
            <Label>Date of Violation</Label>
            <DatetimePickerTrigger
              className="violation-date-trigger"
              closeOnSelectDay={true}
              maxDate={moment()}
              moment={violationDate}
              showTimePicker={false}
              onChange={moment => {
                setViolationDate(moment);
                setWasChanged(true);
              }}
            >
              <Input
                style={{ width: 150 }}
                type="text"
                id="collectedDate"
                value={violationDate ? violationDate.format('MM/DD/YYYY') : ''}
                readOnly
              />
            </DatetimePickerTrigger>
          </FormGroup>
        </Col>
        <Col>
          <Label>Current Status</Label>
          {getViolationStatusLabel(violation?.status)}
        </Col>
      </RowWithSpacer>
      <Row>
        <Col>
          <FormGroup>
            <Label>Badge suspended?</Label>
            <RadioGroup
              row
              name="isBadgeSuspended"
              value={`${isBadgeSuspended}`}
              onChange={event => {
                setIsBadgeSuspended(event?.target?.value === 'true');
                setWasChanged(true);
              }}
            >
              <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>Badge confiscated?</Label>
            <RadioGroup
              row
              name="isBadgeConfiscated"
              value={`${isBadgeConfiscated}`}
              onChange={event => {
                setIsBadgeConfiscated(event?.target?.value === 'true');
                setWasChanged(true);
              }}
            >
              <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>Fine Amount</Label>
            <InputGroup style={{ width: 150 }}>
              <InputGroupText>$</InputGroupText>
              <Input
                type="number"
                value={fineAmount}
                onFocus={event => {
                  const amount = event?.target?.value;
                  if (Number(amount) === 0) {
                    setFineAmount('');
                  }
                }}
                onBlur={event => {
                  const amount = event?.target?.value;
                  if (Number(amount) === 0) {
                    setFineAmount(0);
                  }
                }}
                onChange={event => {
                  const amount = event?.target?.value;
                  // Regex pattern to match numbers with up to 2 decimal places
                  const decimalRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
                  if (!amount || Number(amount) < 0) {
                    setFineAmount(0);
                  } else if (decimalRegex.test(amount)) {
                    setFineAmount(Number(amount));
                  }
                  setWasChanged(true);
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>Is retraining required?</Label>
            <RadioGroup
              row
              name="offenderOption"
              value={`${isRetrainingRequired}`}
              onChange={event => {
                setIsRetrainingRequired(event?.target?.value === 'true');
                setWasChanged(true);
              }}
            >
              <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </FormGroup>
        </Col>
      </Row>
      <RowWithSpacer>
        <Col>
          <FormGroup>
            <Label className="required">Description</Label>
            <Input
              type="textarea"
              rows={4}
              value={description}
              onChange={event => {
                setDescription(event?.target?.value);
                setWasChanged(true);
              }}
            />
          </FormGroup>
        </Col>
      </RowWithSpacer>
      <Row>
        <Col>
          <FormGroup>
            <Label>
              Additional Comments <i style={{ fontSize: 'smaller' }}>(optional)</i>
            </Label>
            <InputWithCharacterLimit
              limit={255}
              type="textarea"
              value={comment}
              onChange={value => {
                setComment(value);
                setWasChanged(true);
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </AirBadgeModal>
  );
}
