import React, { useEffect, useState } from 'react';
import Cropper from 'cropperjs';
import { Button, ButtonGroup } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import { image, text } from '@pdfme/schemas';
import { generate } from '@pdfme/generator';
import { colors } from '../../../../App';
import FontAwesomeIcon from '../../../../shared/components/FontAwesome/FontAwesomeIcon';

function ToolbarButton({ icon, label, onClick }) {
  return (
    <Button color="primary" title={label} onClick={onClick}>
      <FontAwesomeIcon color="white" className={`${icon} mr-2`} style={{ fontSize: '1rem', marginTop: 2 }} />
    </Button>
  );
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 0.5,
    marginRight: theme.spacing(2),
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 0.5,
    marginLeft: theme.spacing(2),
  },
}));

export default function CropBadgePhotoForm({ badgeTemplate, imageFile, onImageCropped }) {
  const classes = useStyles();
  const [cropper, setCropper] = useState(null);

  const onCropImageClicked = async () => {
    const croppedCanvas = cropper.getCroppedCanvas();
    const imageData = croppedCanvas.toDataURL('image/jpeg');
    badgeTemplate.sampledata[0].faceImage = imageData;
    const pdf = await generate({
      template: badgeTemplate,
      plugins: { text, image },
      inputs: badgeTemplate.sampledata,
    });
    const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
    document.getElementById('badgeMock').src = URL.createObjectURL(blob);
    onImageCropped(imageData);
  };

  const setup = () => {
    const cropperCanvas = document.getElementById('cropImage');
    const reader = new FileReader();
    reader.onload = async function (e) {
      const imageData = e.target.result;
      badgeTemplate.sampledata[0].faceImage = imageData;
      const pdf = await generate({
        template: badgeTemplate,
        plugins: { text, image },
        inputs: badgeTemplate.sampledata,
      });
      const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
      document.getElementById('badgeMock').src = URL.createObjectURL(blob);

      cropperCanvas.src = imageData;
      const cropper = new Cropper(cropperCanvas, {
        viewMode: 1,
        autoCropArea: 0.5,
        zoomOnWheel: false,
        aspectRatio: 3 / 4,
        responsive: true,
        minContainerWidth: 405,
        minContainerHeight: 600,
      });
      setCropper(cropper);
      onImageCropped(imageData);
    };
    reader.readAsDataURL(imageFile);
  };

  useEffect(() => {
    if (badgeTemplate && imageFile) {
      cropper && cropper.destroy();
      setCropper(null);
      setup();
    }

    return () => {
      cropper && cropper.destroy();
      setCropper(null);
    };
  }, [badgeTemplate, imageFile]);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.leftColumn}>
          <img
            id="cropImage"
            src=""
            alt="Cropper Canvas"
            style={{
              width: 360,
              height: 480,
              border: `3px solid ${colors.secondary}`,
              display: 'none',
            }}
          />
        </div>
        <div className={classes.rightColumn}>
          <iframe
            id="badgeMock"
            style={{ height: 600, width: 400, display: badgeTemplate ? 'inline-block' : 'none' }}
          ></iframe>
        </div>
      </div>
      <div className={classes.wrapper} style={{ marginBottom: 24 }}>
        <div className={classes.leftColumn}>
          <div
            style={{
              width: '100%',
              marginTop: 16,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 16,
              }}
            >
              <ButtonGroup size="sm" className="mr-2">
                <ToolbarButton
                  icon="fa-solid fa-magnifying-glass-plus"
                  label="Zoom In"
                  onClick={() => cropper.zoom(0.1)}
                />
                <ToolbarButton
                  icon="fa-solid fa-magnifying-glass-minus"
                  label="Zoom Out"
                  onClick={() => cropper.zoom(-0.1)}
                />
              </ButtonGroup>

              <ButtonGroup size="sm" className="mr-2">
                <ToolbarButton
                  icon="fa-solid fa-arrow-rotate-left"
                  label="Rotate Left"
                  onClick={() => cropper.rotate(-45)}
                />
                <ToolbarButton
                  icon="fa-solid fa-arrow-rotate-right"
                  label="Rotate Right"
                  onClick={() => cropper.rotate(45)}
                />
              </ButtonGroup>

              <ButtonGroup size="sm">
                <ToolbarButton icon="fa-solid fa-arrow-left" label="Move Left" onClick={() => cropper.move(-10, 0)} />
                <ToolbarButton icon="fa-solid fa-arrow-right" label="Move Right" onClick={() => cropper.move(10, 0)} />
                <ToolbarButton icon="fa-solid fa-arrow-up" label="Move Up" onClick={() => cropper.move(0, -10)} />
                <ToolbarButton icon="fa-solid fa-arrow-down" label="Move Down" onClick={() => cropper.move(0, 10)} />
              </ButtonGroup>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <Button color="primary" onClick={() => cropper.reset()}>
                <i className="fa-solid fa-clock-rotate-left mr-2"></i>
                Reset
              </Button>

              <Button color="primary" onClick={onCropImageClicked}>
                <i className="fa-solid fa-crop-simple mr-2"></i>
                Crop Image
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.rightColumn}>&nbsp;</div>
      </div>
    </>
  );
}
