import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import AirBadgeModal, { MODAL_SIZE_LARGE } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import FileUploadForm from '../../../../shared/components/FileUploadModal/FileUploadForm';
import { AppContext, colors } from '../../../../App';
import request from '../../../../ajax/Request';
import { handleError } from '../../../../utils';
import TitledBox from '../../../../shared/TitledBox/TitledBox';
import CropBadgePhotoForm from './CropBadgePhotoForm';

let requestTimeout = null;
let abortController = null;

export default function UseExistingPhotoModal({ isOpen, onClose, airportPersonId, badgeTemplate }) {
  const app = useContext(AppContext);
  const [files, setFiles] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [isCropFormShown, setIsCropFormShown] = useState(false);
  const [imageData, setImageData] = useState(null);

  const reset = () => {
    setFiles([]);
    setExistingImages([]);
    setIsCropFormShown(false);
  };

  const onAddImageToBadgeClicked = () => {
    reset();
    onClose({ imageData });
  };

  const onCloseModal = () => {
    reset();
    onClose();
    //
  };

  const useExistingPhoto = imageData => {
    reset();
    onClose({ imageData });
  };

  const useUploadedPhoto = async () => {
    setIsCropFormShown(true);
  };

  const onFilesAdded = files => {
    setFiles(files);
  };

  const loadImages = () => {
    if (abortController) abortController.abort();
    if (requestTimeout) clearTimeout(requestTimeout);

    app.api.toggleLoading(true);

    requestTimeout = setTimeout(() => {
      abortController = new AbortController();
      request('authenticated-user', 'GET', `people/${airportPersonId}/recent-photos`, null, abortController.signal)
        .then(({ profilePic, badgeImages }) => {
          const images = [];
          if (profilePic) {
            images.push({
              source: 'Profile',
              image: `data:image/jpeg;base64,${profilePic}`,
            });
          }
          if (badgeImages?.length) {
            badgeImages.forEach(image => {
              images.push({
                source: 'Badge',
                image: `data:image/jpeg;base64,${image}`,
              });
            });
          }
          setExistingImages(images);
        })
        .catch(error => handleError({ error }))
        .finally(() => app.api.toggleLoading(false));
    }, 500);
  };

  useEffect(() => {
    if (isOpen && airportPersonId) {
      loadImages();
    }

    return () => {
      if (abortController) abortController.abort();
      if (requestTimeout) clearTimeout(requestTimeout);
    };
  }, [isOpen, airportPersonId]);

  if (!isOpen || !airportPersonId) return null;

  return (
    <AirBadgeModal
      size={MODAL_SIZE_LARGE}
      style={{ maxWidth: 875 }}
      title="Upload New or Select Existing Photo"
      isOpen={isOpen}
      onClose={onCloseModal}
      saveLabel="Add Image To Badge"
      onSave={isCropFormShown ? onAddImageToBadgeClicked : null}
      leftSideExtra={
        isCropFormShown ? (
          <Button onClick={() => setIsCropFormShown(false)}>
            <i className="fa-solid fa-chevron-left mr-2"></i>Go Back
          </Button>
        ) : null
      }
    >
      <div style={isCropFormShown ? { display: 'none' } : undefined}>
        <TitledBox title="SELECT A RECENT PHOTO" style={{ paddingBottom: 24 }}>
          {existingImages?.length ? (
            <Grid container spacing={3} direction="row" alignItems="center" justifyContent="space-around">
              {existingImages.map((entry, idx) => (
                <Grid
                  container
                  item
                  key={idx}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sm={12}
                  md={4}
                >
                  <Grid item>
                    <img
                      src={entry.image}
                      alt="Recent Photo"
                      style={{
                        width: 200,
                        height: 266,
                        border: `3px solid ${colors.secondary}`,
                      }}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: 16 }}>
                    <Button color="primary" onClick={() => useExistingPhoto(entry.image)}>
                      <i className="fa-regular fa-image mr-2"></i>Use {entry.source} Photo
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div style={{ textAlign: 'center', fontStyle: 'italic' }}>No recent photos</div>
          )}
        </TitledBox>

        <div className="separator mt-5 mb-5">OR</div>

        <TitledBox title="UPLOAD A NEW PHOTO" style={{ paddingBottom: 24 }}>
          <FileUploadForm
            fileUploadOnly={true}
            hideTSCWarning={true}
            onFilesAdded={onFilesAdded}
            allowedFileTypes={{
              'image/png': ['.png'],
              'image/jpg': ['.jpg'],
              'image/jpeg': ['.jpeg'],
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }}>
            <Button color="primary" disabled={!files.length} onClick={useUploadedPhoto}>
              <i className="fa-solid fa-cloud-arrow-up mr-2"></i>Use Uploaded Photo
            </Button>
          </div>
        </TitledBox>
      </div>

      <div style={!isCropFormShown ? { display: 'none' } : undefined}>
        <CropBadgePhotoForm badgeTemplate={badgeTemplate} imageFile={files[0]} onImageCropped={setImageData} />
      </div>

      <div>&nbsp;</div>
    </AirBadgeModal>
  );
}
