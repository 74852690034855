import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Row } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { styled } from '@material-ui/core/styles';
import { AppContext } from '../../../App';

const FileUploadColumn = styled(Col)(({ theme }) => ({
  marginBottom: 0,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const defaultAllowedFileTypes = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'application/pdf': ['.pdf'],
};

export default function FileUploadForm({
  hideTSCWarning = false,
  onFilesAdded,
  fileUploadOnly,
  allowedFileTypes = { ...defaultAllowedFileTypes },
  maxFiles = 1,
}) {
  const app = useContext(AppContext);
  const { t } = useTranslation('translation', { keyPrefix: 'fileUploadForm' });
  const [files, setFiles] = useState([]);
  const [scannedFiles, setScannedFiles] = useState([]);
  const [DWObject, setDWObject] = useState(null);
  const [hasFileSizeError, setHasFileSizeError] = useState(false);
  const [hasTscFileSizeError, setHasTscFileSizeError] = useState(false);
  const extensions = Object.keys(allowedFileTypes).flatMap(ft => allowedFileTypes[ft]);
  const helpText = t('filePickerHelp2')
    .replace('{{fileTypes}}', extensions.join(', '))
    .replace(new RegExp(',([^,]*)$'), ' and$1');

  const addFiles = files => {
    setHasFileSizeError(false);
    setHasTscFileSizeError(false);
    let errorFound = false;
    if (files?.length) {
      files.forEach(f => {
        const maxTscSize = 1999999; // bytes
        const megabytes = f.size / 1000000;
        const maxUploadSize = app.maxUploadSize;
        if (megabytes > maxUploadSize) {
          setHasFileSizeError(true);
          errorFound = true;
        }
        if (f.size > maxTscSize) {
          setHasTscFileSizeError(true);
        }
      });
    }

    if (!errorFound) {
      onFilesAdded(files);
    } else {
      onFilesAdded([]);
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles);
    setScannedFiles([]);
    addFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxFiles,
    accept: allowedFileTypes,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused
        ? {
            borderColor: app.primaryColor,
          }
        : {}),
      ...(isDragAccept
        ? {
            borderColor: app.successColor,
          }
        : {}),
      ...(isDragReject
        ? {
            borderColor: app.dangerColor,
          }
        : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const onCaptureImageClick = async () => {
    // Setup event handler
    DWObject.Addon.Camera.on('video-closed', () => {
      let type = null;
      let indices = [];
      let extension = '';
      if (DWObject.HowManyImagesInBuffer === 1) {
        type = window.Dynamsoft.DWT.EnumDWT_ImageType.IT_JPG;
        extension = '.jpg';
        indices = [0];
      } else if (DWObject.HowManyImagesInBuffer > 1) {
        type = window.Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF;
        extension = '.pdf';
        indices = [...Array(DWObject.HowManyImagesInBuffer).keys()];
      }

      if (DWObject.HowManyImagesInBuffer > 0) {
        DWObject.ConvertToBlob(
          indices,
          type,
          result => {
            try {
              const file = new File([result], `ScannedImage${extension}`, { type: result.type });
              setFiles([]);
              setScannedFiles([file]);
              addFiles([file]);
            } catch (e) {
              console.error(e);
            }
          },
          (errorCode, errorString) => console.error(errorCode, errorString)
        );
      }

      setTimeout(() => {
        DWObject.RemoveAllImages();
      }, 500);
    });

    // Start the webcam scanner
    DWObject.Addon.Camera.scanDocument({
      scannerViewer: {
        enableBorderDetection: true,
        resolution: { visibility: true },
        autoScan: { visibility: false },
        autoDetect: { visibility: true, enableAutoDetect: true, acceptedPolygonConfidence: 80 },
        continuousScan: { visibility: false, enableContinuousScan: true },
        switchCamera: { visibility: false },
        loadLocalFile: { visibility: false },
      },
      filterViewer: { exitDocumentScanAfterSave: true, filter: { visibility: false } },
    });
  };

  useEffect(() => {
    setHasFileSizeError(false);
    setHasTscFileSizeError(false);
    window.Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
      const obj = window.Dynamsoft.DWT.GetWebTwain('dwtcontrolContainer');
      obj.Viewer.setViewMode(2, 2);
      setDWObject(obj);
    });
    window.Dynamsoft.DWT.Load();
  }, []);

  return (
    <>
      <Row>
        <FileUploadColumn sm="12" lg={fileUploadOnly ? 12 : 6}>
          <div {...getRootProps({ className: 'dropzone', style })}>
            <input {...getInputProps()} />
            <div style={{ marginBottom: 10 }}>{t('filePickerHelp')}</div>
            <Button size="sm">{t('filePickerButton')}</Button>
            <style scoped>{`.fileName { margin-top: 15px; font-weight: bold; }`}</style>
            {files.length ? (
              <div
                className="fileName"
                style={{ fontSize: 'larger', opacity: 0.5, color: 'black' }}
              >{`${files.map(f => f.name).join(', ')}`}</div>
            ) : (
              <div className="fileName">{`(${helpText})`}</div>
            )}
          </div>
        </FileUploadColumn>
        {!fileUploadOnly && (
          <Col sm="12" lg="6">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                flexDirection: 'column',
                height: '100%',
                borderWidth: 2,
                borderRadius: 2,
                borderColor: 'rgb(238, 238, 238)',
                borderStyle: 'dashed',
                backgroundColor: 'rgb(250, 250, 250)',
                color: 'rgb(189, 189, 189)',
              }}
            >
              <div style={{ marginBottom: 10 }}>{t('fileScannerHelp')}</div>
              <Button size="sm" onClick={onCaptureImageClick}>
                {t('fileScannerButton')}
              </Button>
              {scannedFiles.length ? (
                <div
                  className="fileName"
                  style={{ fontSize: 'larger', opacity: 0.5, color: 'black' }}
                >{`${scannedFiles.map(f => f.name).join(', ')}`}</div>
              ) : (
                <div className="fileName">{`(${t('fileScannerHelp2')})`}</div>
              )}
            </div>
          </Col>
        )}
      </Row>
      <Row>
        {hasFileSizeError && (
          <div style={{ width: '100%', padding: '0 15px' }}>
            <Alert fade={false} className="mt-4 text-center" color="danger">
              File size cannot exceed {app.maxUploadSize} MB
            </Alert>
          </div>
        )}
        {!hideTSCWarning && hasTscFileSizeError && (
          <div style={{ width: '100%', padding: '0 15px' }}>
            <Alert fade={false} className={hasFileSizeError ? 'mt-2 text-center' : 'mt-4 text-center'} color="warning">
              If this file is going to be transmitted to TSC as part of a background check, the file size cannot exceed
              1.9 MB
            </Alert>
          </div>
        )}
      </Row>
    </>
  );
}
